require('../css/app.scss');
const $ = require('jquery');
import validate from 'jquery-validation'

//import $ from 'jquery';


global.$ = global.jQuery = $;

require('bootstrap')
var hljs = require('highlightjs')

hljs.initHighlightingOnLoad();


let googleRecaptchaPublickey = '';

function setGoogleRecaptchaPublickey(key){
    googleRecaptchaPublickey = key;
}
// Get token from API
function check_grecaptcha() {
    grecaptcha.ready(function () {
        grecaptcha.execute(googleRecaptchaPublickey, {
            action: "ajaxForm"
        }).then(function (token) {
            $("[name='recaptcha-token']").val(token);
        });
    });
}


$(function() {
    check_grecaptcha();
    $("form").validate({
        rules: {
            name: {
                required: true,
                minlength: 3
            },
            email: {
                required: true,
                email: true
            },
            message: {
                required: true,
                minlength: 5
            }
        },
        // Customize your messages
        messages: {
            name: {
                required: "Please enter your name.",
                minlength: "Must be at least 3 characters long."
            },
            email: "Please enter a valid email.",
            message: {
                required: "Please enter your message.",
                minlength: "Must be at least 5 characters long."
            }
        },
        errorClass: "invalid-feedback",
        highlight: function (element) {
            $(element).addClass("is-invalid").removeClass("is-valid");
        },
        unhighlight: function (element) {
            $(element).addClass("is-valid").removeClass("is-invalid");
        },
        submitHandler: function (form) {
            $(".spinner-border").removeClass("d-none");
            $.get(form.action, $(form).serialize())
                .done(function (response) {
                    $(".toast-body").html(JSON.parse(response));
                    $(".toast").toast('show');
                    $(".spinner-border").addClass("d-none");
                    $("#submit-btn").prop("disabled", true);
                    check_grecaptcha();
                    setTimeout(function () {
                        $("#submit-btn").prop("disabled", false);
                        $("form").trigger("reset");
                        $("form").each(function () {
                            $(this).find(".form-control").removeClass("is-valid")
                        })
                    }, 3000);
                })
                .fail(function (response) {
                    $(".toast-body").html(JSON.parse(response));
                    $(".toast").toast('show');
                    $(".spinner-border").addClass("d-none");
                });
        }
    });
});


global.setGoogleRecaptchaPublickey = setGoogleRecaptchaPublickey;


require('./theme/jquery.appear');
require('./theme/jquery.matchHeight-min');
require('./theme/owl.carousel.min');
require('./theme/jquery-countTo');
require('./theme/parallaxie');
require('./theme/jquery.cubeportfolio.min');
require('./theme/jquery.fancybox.min');
require('./theme/jquery.background-video');
require('./theme/typewriter');
require('./theme/particles.min');

/*
const WOW = require('./theme/wow.min');
console.log(WOW);
global.WOW = WOW;
*/

require('./theme/functions');

/*
<!--Revolution SLider-->
<script src="js/revolution/jquery.themepunch.tools.min.js"></script>
<script src="js/revolution/jquery.themepunch.revolution.min.js"></script>
<!-- SLIDER REVOLUTION 5.0 EXTENSIONS  (Load Extensions only on Local File Systems !  The following part can be removed on Server for On Demand Loading) -->
<script src="js/revolution/extensions/revolution.extension.actions.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.carousel.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.kenburn.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.layeranimation.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.migration.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.navigation.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.parallax.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.slideanims.min.js"></script>
<script src="js/revolution/extensions/revolution.extension.video.min.js"></script>
*/



